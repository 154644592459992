/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser } from "@remix-run/react"
//import * as Sentry from "@sentry/remix"
import { startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"
/*
Sentry.init({
  dsn: "https://6d313894bd423f6e7f40d5f97022119d@o4506770471780352.ingest.us.sentry.io/4506770471911424",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [Sentry.browserTracingIntegration({
    useEffect,
    useLocation,
    useMatches
  })]
})
*/

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})